import { Flex, Link, Text } from '@chakra-ui/layout';
import Container from '@components/LayoutStructure/Container';
import { getBackgroundColor, getColor } from '@utils/getColor';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { FooterNavigation } from '../Navigation.types';

export interface FooterProps {
  main: FooterNavigation['main'];
  backgroundColor?: string;
  backgroundColorRange?: string;
}

const FooterSEM: React.FC<FooterProps> = ({
  main,
  backgroundColor = 'primary',
  backgroundColorRange = '700',
}) => {
  return (
    <Container
      ignoreMaxWidth
      as="footer"
      role="contentinfo"
      bg={getBackgroundColor(backgroundColor, backgroundColorRange)}
      textColor="white"
    >
      <Container py="16">
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection={{
            base: 'column',
            md: 'row',
          }}
          minWidth={{ md: 'xl' }}
          rowGap={8}
          columnGap={20}
        >
          {main.map((item, i) => (
            <Text
              fontSize="md"
              align="center"
              key={i}
              textColor={getColor(item.textColor, item.textColorRange)}
            >
              {item.type === 'link' && item.link.url ? (
                <Link
                  href={item.link.url}
                  color={getColor(item.textColor, item.textColorRange)}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                >
                  {item.text}
                </Link>
              ) : (
                item.text
              )}
            </Text>
          ))}
        </Flex>
      </Container>
    </Container>
  );
};

export default withHydrationOnDemand({
  on: ['visible'],
})(FooterSEM);
