import { ChatOverlayProps } from '@components/ChatOverlay/ChatOverlay';
import { PageTemplateKeys } from '@components/config';
import dynamic from 'next/dynamic';
import { Page } from '~/types/Magnolia';
import { FooterNavigation } from '../Navigation.types';
import Footer from './Footer';
import FooterSEM from './FooterSEM';

const ChatOverlay = dynamic<ChatOverlayProps>(
  () => import('@components/ChatOverlay/ChatOverlay'),
  { ssr: false }
);

export interface FooterContainerProps {
  mainFooter: FooterNavigation;
  footerSem: FooterNavigation;
  page?: Page;
  template: PageTemplateKeys;
}

const FooterContainer: React.FC<FooterContainerProps> = ({
  mainFooter,
  page,
  footerSem,
  template,
}) => {
  const isSemPage = template === 'spa-lm:pages/sem';
  const footer = isSemPage && footerSem ? footerSem : mainFooter;

  return (
    <>
      {isSemPage ? (
        <FooterSEM
          main={footer.main}
          backgroundColor={footer.bgColor}
          backgroundColorRange={footer.bgColorRange}
        />
      ) : (
        <Footer
          intro={footer.intro}
          logo={footer.logo}
          main={footer.main}
          secondary={footer.secondary}
          backgroundColor={footer.bgColor}
          backgroundColorRange={footer.bgColorRange}
        />
      )}
      {footer.chatOverlay && (
        <ChatOverlay
          page={page}
          {...footer.chatOverlay}
          metadata={footer.metadata}
        />
      )}
    </>
  );
};

export default FooterContainer;
